import "./styles.css";
import station from "./assets/Group.svg";
import Render from "./assets/Render1.png";

export default function App() {
  return (
    <section>

<div class="header">
  <h1>Large Format Retail <br /> 
  Centre Arriving Soon</h1>
  <img className="logo" alt="" src={station} />
 </div>


      <div className="white-line" />
      <div className="orange-line" />
      <div class="footer">
      <div className="render" style={{ backgroundImage: `url(${Render})` }} />
          <p>For enquiries please contact <br />bruce@cartergroup.co.nz <br />or 0800 836 156</p></div>
    </section>
  );
}
